<template>
  <v-card>
    <v-card-text :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'">
      <span class="text-h4 font-weight-black"> Order List </span>

      <v-breadcrumbs
        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
        :items="orderListInfo"
      ></v-breadcrumbs>

      <!-- V-Card For Tab Items, Etc -->
      <v-card elevation="4">
        <v-card-text :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'">
          <v-tabs v-model="currentTab" class="mb-4" fixed-tabs>
            <v-tab v-for="(item, i) in tabItems" :key="i + '-tabItems'" @click="resetSelection">
              {{ item.name }}
            </v-tab>
          </v-tabs>
          <v-row>
            <v-col cols="12" lg="3" md="2" xs="12">
              <v-text-field
                v-model="searchTarget"
                outlined
                rounded
                hide-details
                required
                :append-icon="icons.mdiMagnify"
                label="Search Product"
                class="mb-3"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="3" xs="12">
              <v-select
                v-model="selectedFilter"
                :items="filterOptions"
                outlined
                rounded
                hide-details
                required
                attach
                item-text="label"
                item-value="value"
                :append-icon="icons.mdiChevronDown"
                :label="`Filter`"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" lg="3" md="3" xs="12">
              <v-select
                v-model="selectedSort"
                :items="sortOptions"
                outlined
                rounded
                hide-details
                item-text="label"
                item-value="value"
                required
                attach
                :append-icon="icons.mdiChevronDown"
                :label="`Sort`"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" lg="3" md="4" xs="12" class="mt-n2">
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :label="$t('broadcasts.date')"
                    :prepend-icon="icons.mdiCalendarMonth"
                    :value="dates.join(' - ')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker v-model="dates" no-title scrollable range @change="loadOrderDataOnChange">
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
            <!-- <v-col
              cols="12"
              lg="6"
              md="6"
              xs="12"
            >
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="selectedFilter"
                    :items="filterOptions"
                    outlined
                    rounded
                    hide-details
                    required
                    attach
                    item-text="label"
                    item-value="value"
                    :append-icon="icons.mdiChevronDown"
                    :label="`Filter`"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    outlined
                    rounded
                    hide-details
                    required
                    attach
                    :append-icon="icons.mdiChevronDown"
                    :label="`Sort`"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="$t('broadcasts.date')"
                        :prepend-icon="icons.mdiCalendarMonth"
                        :value="dates.join(' - ')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      no-title
                      scrollable
                      range
                      @change="loadOrderDataOnChange"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Order List View -->
      <div v-if="orders.length !== 0">
        <!-- Update Status Element, Etc -->
        <v-row>
          <v-col cols="12">
            <div class="d-flex">
              <v-checkbox v-model="selectBulk" color="#5E5669" @click="chooseAllOrder"></v-checkbox>
              <v-menu :disabled="selectedOrders.length === 0" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="#C4C4C4" class="my-auto" v-bind="attrs" v-on="on">
                    <span :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      >Update All Status</span
                    >
                    <v-icon color="#000000">
                      {{ icons.mdiChevronDown }}
                    </v-icon>
                  </v-chip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(status, indexStat) in 5"
                    :key="`status-key + ${indexStat}`"
                    @click="bulkUpdateOrderStatus(indexStat)"
                  >
                    <v-list-item-title>
                      <v-chip :color="getColor(indexStat)">
                        {{ getStatusName(indexStat) }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>

        <!-- Orders & Products Card -->
        <v-card v-for="(order, indexOrder) in orders" :key="order._id" class="mb-2" elevation="6">
          <v-card-text>
            <!-- Item Breadcrumbs, Checkbox, Order Type and Status -->
            <v-row>
              <v-col cols="12">
                <div v-if="!$vuetify.breakpoint.xs" class="d-flex">
                  <v-checkbox
                    v-model="selectOrder[indexOrder].value"
                    color="#5E5669"
                    @click="setOrder(order._id, indexOrder)"
                  ></v-checkbox>
                  <v-breadcrumbs
                    class="me-2"
                    :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                    :items="order.breadCrumbs"
                  ></v-breadcrumbs>
                  <v-chip :color="getColorOrderType(order.orderType)" class="me-2 my-auto">
                    <span :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: white;'">{{
                      order.orderType
                    }}</span>
                  </v-chip>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :color="getColor(order.status)" class="my-auto" v-bind="attrs" v-on="on">
                        <span :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: white;'">{{
                          getStatusName(order.status)
                        }}</span>
                        <v-icon color="#000000">
                          {{ icons.mdiChevronDown }}
                        </v-icon>
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(status, indexStatus) in 5"
                        :key="`status-key + ${indexStatus}`"
                        @click="changeOrderStatus(order._id, indexStatus)"
                      >
                        <v-list-item-title>
                          <v-chip :color="getColor(indexStatus)">
                            {{ getStatusName(indexStatus) }}
                          </v-chip>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div v-else class="mb-12">
                  <v-row>
                    <v-col cols="12">
                      <div class="d-flex">
                        <v-checkbox color="#5E5669"></v-checkbox>
                        <v-breadcrumbs
                          class="me-2"
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          :items="order.breadCrumbs"
                        ></v-breadcrumbs>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="6">
                          <div class="text-center">
                            <v-chip :color="getColorOrderType(order.orderType)" class="my-auto">
                              <span :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'">{{
                                order.orderType
                              }}</span>
                            </v-chip>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="text-center">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip :color="getColor(order.status)" class="my-auto" v-bind="attrs" v-on="on">
                                  <span :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'">{{
                                    getStatusName(order.status)
                                  }}</span>
                                  <v-icon color="#000000">
                                    {{ icons.mdiChevronDown }}
                                  </v-icon>
                                </v-chip>
                              </template>
                              <v-list>
                                <v-list-item v-for="(status, indexStatus) in 5" :key="`status-key + ${indexStatus}`">
                                  <v-list-item-title>
                                    <v-chip :color="getColor(indexStatus)">
                                      {{ getStatusName(indexStatus) }}
                                    </v-chip>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>

            <!-- Product Ordered and Order Details -->
            <v-row class="mx-6">
              <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                <v-row>
                  <v-col v-for="(orderData, index) in order.itemsOrdered" :key="index + '-orderdata'" cols="12">
                    <div v-if="index === 0">
                      <div class="mb-3">
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h5 font-weight-medium text-center"
                          >{{ orderData.name }}{{ orderData.Variant === '-' ? '' : ` - ${orderData.Variant}` }}</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-2 font-weight-medium"
                        >{{ orderData.Quantity }}x {{ currency(orderData['Harga(@)']) }}</span
                      >

                      <v-row v-if="orderData.listAddon && orderData.listAddon.length !== 0" class="mt-3">
                        <v-col v-for="(addon, indexAddon) in orderData.listAddon" :key="indexAddon" cols="12">
                          <v-row v-if="addon.text !== '' && addon.price !== 0">
                            <v-col cols="6">
                              <span
                                :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                class="text-h7 font-weight-medium text-center"
                                >{{ addon.text }}</span
                              >
                            </v-col>
                            <v-col cols="6">
                              <span
                                :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                class="text-h7 font-weight-medium text-center"
                                >{{ currency(addon.price) }}</span
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="index !== 0 && showDetail[indexOrder].value">
                      <div class="mb-3">
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h5 font-weight-medium text-center"
                          >{{ orderData.name }}{{ orderData.Variant === '-' ? '' : ` - ${orderData.Variant}` }}</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-2 font-weight-medium"
                        >{{ orderData.Quantity }}x {{ currency(orderData['Harga(@)']) }}</span
                      >

                      <v-row v-if="orderData.listAddon && orderData.listAddon.length !== 0" class="mt-3">
                        <v-col v-for="(addon, indexAddon) in orderData.listAddon" :key="indexAddon" cols="12">
                          <v-row>
                            <v-col cols="6">
                              <span
                                :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                class="text-h7 font-weight-medium text-center"
                                >{{ addon.text }}</span
                              >
                            </v-col>
                            <v-col cols="6">
                              <span
                                :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                class="text-h7 font-weight-medium text-center"
                                >{{ currency(addon.price) }}</span
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="order.itemsOrdered.length > 1">
                  <v-col class="text-center" cols="12">
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="text-h7 me-2 font-weight-medium"
                      >{{
                        !showDetail[indexOrder].value ? `See ${order.itemsOrdered.length - 1} more product` : 'See Less'
                      }}</span
                    >
                    <v-btn icon @click="showMoreProduct(order._id, indexOrder)">
                      <v-icon
                        size="14"
                        :color="$vuetify.theme.isDark === true ? '#ffffff' : '#000000'"
                        class="toggleUpDown"
                        :class="{ rotate: showDetail[indexOrder].value }"
                      >
                        {{ icons.mdiTriangle }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- <img
                    class="shop-logo me-4"
                    src="https://previews.123rf.com/images/prettyvectors/prettyvectors1502/prettyvectors150200051/36241121-shop-vector-illustration.jpg"
                  /> -->
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.xs ? 'border-divider-top' : 'border-divider'"
                cols="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
              >
                <v-row :class="$vuetify.breakpoint.xs ? '' : 'ms-2'">
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <!-- Alamat -->
                    <div v-if="order.orderDetails.Alamat">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.address') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails.Alamat.value }}</span
                      >
                    </div>

                    <!-- Tanggal -->
                    <div v-if="order.orderDetails.Tanggal">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.date') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ $moment(order.orderDetails.Tanggal.value).format('DD MMM YYYY') }}</span
                      >
                    </div>

                    <!-- ID Table Reservation -->
                    <div v-if="order.orderDetails['Id Meja']">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.idTable') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails['Id Meja'].value }}</span
                      >
                    </div>

                    <!-- Room Number -->
                    <div v-if="order.orderDetails['Room Number']">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.roomNo') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails['Room Number'].value }}</span
                      >
                    </div>

                    <!-- Jam Reservasi -->
                    <div v-if="order.orderDetails.Jam">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.time') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails.Jam.value }}:00</span
                      >
                    </div>

                    <!-- Note -->
                    <div v-if="order.orderDetails.Note">
                      <div class="mt-2">
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.note') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails.Note.value }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" xs="12">
                    <!-- Pajak -->
                    <div v-if="order.orderDetails.Pajak">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ `${$t('EOrdering.taxlabel')} ${order.orderDetails.Pajak.label}` }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ currency(order.orderDetails.Pajak.value) }}</span
                      >
                    </div>

                    <!-- Coupon -->
                    <!-- <div v-if="order.orderDetails.Coupon">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                        >{{ $t('EOrdering.coupon') }} :</span>
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                      >{{ currency(order.orderDetails.Coupon.value) }}</span>
                    </div> -->

                    <!-- Discount -->
                    <div v-if="order.orderDetails.Discount">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ `${$t('EOrdering.discountlabel')} ${order.orderDetails.Discount.label}` }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ currency(order.orderDetails.Discount.value) }}</span
                      >
                    </div>

                    <!-- Item Addon -->
                    <div v-if="order.orderDetails.AddOn">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.time') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails.AddOn.value }}</span
                      >
                    </div>

                    <!-- Cover                                            <div v-if="order.orderDetails.Jam"> -->
                    <div v-if="order.orderDetails.Cover">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.cover') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails.Cover.value }}</span
                      >
                    </div>

                    <!-- Courier -->
                    <div v-if="order.orderDetails.Kurir">
                      <div>
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-subtitle-1 font-weight-black"
                          >{{ $t('EOrdering.courier') }} :</span
                        >
                      </div>
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ order.orderDetails.Kurir.value }}</span
                      >
                      <br />
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-subtitle-1 font-weight-medium"
                        >{{ currency(order.orderDetails['Ongkos Kirim'].value) }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Total Sales Component -->
            <v-card
              class="mt-2"
              elevation="5"
              :style="$vuetify.theme.isDark === true ? 'background: #222222;' : 'background: #F8F9F9;'"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="text-h6 font-weight-black"
                      >Total Sales</span
                    >
                    <v-menu open-on-hover top offset-x rounded>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="ms-1"
                          :color="$vuetify.theme.isDark === true ? 'color: #ffffff;' : 'color: #000000;'"
                          v-on="on"
                        >
                          {{ icons.mdiInformation }}
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <v-row>
                              <v-col cols="3">
                                <span
                                  :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                  class="text-subtitle-2 font-weight-bold"
                                  >Total Sales</span
                                >
                              </v-col>
                              <v-col cols="2"> </v-col>
                              <v-col cols="7">
                                <span
                                  :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                  class="text-subtitle-2 text-end font-weight-bold"
                                  >{{ currency(order.orderDetails['Sub Total'].value) }}</span
                                >
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="order.orderDetails['Ongkos Kirim']">
                          <v-list-item-title>
                            <v-row>
                              <v-col cols="3">
                                <span
                                  :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                  class="text-subtitle-2 font-weight-bold"
                                  >Shipping Cost</span
                                >
                              </v-col>
                              <v-col cols="2"> </v-col>
                              <v-col cols="7">
                                <span
                                  :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                  class="text-subtitle-2 text-end font-weight-bold"
                                  >{{ currency(order.orderDetails['Ongkos Kirim'].value) }}</span
                                >
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item>
                    <v-list-item-title>
                      Total Price
                    </v-list-item-title>
                  </v-list-item> -->
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="text-end">
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="text-h6 font-weight-black"
                      >{{ currency(order.orderDetails.Total.value) }}</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </div>

      <!-- No Order View -->
      <div v-else>
        <v-row>
          <v-col cols="12">
            <div class="flex text-center">
              <img class="mx-auto mt-16" src="./../../assets/images/Empty Order.png" />
            </div>
          </v-col>
          <v-col cols="12">
            <div class="flex text-center">
              <span
                :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                class="text-h4 font-weight-medium"
                >No Order Yet</span
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import eOrderingMixin from '@/mixins/e-orderingMixin'
import inboxMixin from '@/mixins/inboxMixin'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import _orderBy from 'lodash/orderBy'
import { RealtimeClient } from '@supabase/realtime-js'
import { mdiMagnify, mdiTriangle, mdiInformation, mdiFilterVariant, mdiCalendarMonth, mdiChevronDown } from '@mdi/js'

export default {
  name: 'OrderList',
  mixins: [eOrderingMixin, inboxMixin, pouchDbMixin],
  data() {
    return {
      selectedFilter: 'desc',
      selectedSort: '',
      showDetail: [],
      currentTab: 0,
      icons: {
        mdiMagnify,
        mdiFilterVariant,
        mdiCalendarMonth,
        mdiChevronDown,
        mdiInformation,
        mdiTriangle,
      },
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10),
      ],
      selectBulk: false,
      selectOrder: [],
      tabItems: [
        {
          id: 1,
          name: 'All Orders',
        },
        {
          id: 2,
          name: 'Pending',
        },
        {
          id: 3,
          name: 'On Progress',
        },
        {
          id: 4,
          name: 'Paid',
        },
        {
          id: 5,
          name: 'Done',
        },
        {
          id: 6,
          name: 'Canceled',
        },
      ],
      startDate: '',
      endDate: '',
      timeout: null,
      selectedOrders: [],
      itemBreadcrumbs: [
        {
          text: 'Pending',
          disabled: false,
        },
        {
          text: 'order-mn8prllj',
          disabled: false,
        },
        {
          text: '23 Maret 2022 15.59 WIB',
          disabled: false,
        },
      ],
      orderListInfo: [],
      currentSearch: '',
    }
  },
  computed: {
    searchTarget: {
      get() {
        return this.currentSearch
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.currentSearch = val
          this.filterProduct()
        }, 2000)
      },
    },
    filterOptions() {
      return [
        {
          label: 'Newest',
          value: 'desc',
        },
        {
          label: 'Oldest',
          value: 'asc',
        },
      ]
    },
    sortOptions() {
      return [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Delivery',
          value: 'Delivery',
        },
        {
          label: 'Take Away',
          value: 'Take Away',
        },
        {
          label: 'Reservation',
          value: 'Reservation',
        },
        {
          label: 'Dine In',
          value: 'Dine In',
        },
        {
          label: 'Room Order',
          value: 'Room Order',
        },
      ]
    },
    currentShop() {
      return this.$store.getters['eOrdering/getCurrentShop']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    orders() {
      let orderList = JSON.parse(JSON.stringify(this.orderList))

      //! Looping to Get order details and order info and processing data from BE
      orderList.forEach(order => {
        order.itemsOrdered = []
        order.breadCrumbs = []
        for (const key in order.orderData[order.orderData.length - 2]) {
          if (key.includes('Pajak')) {
            order.orderData[order.orderData.length - 2].Pajak = {
              label: `${key.replace(/[Pajak]/g, '')}`,
              value: order.orderData[order.orderData.length - 2][key],
            }
          }

          if (key.includes('Discount')) {
            order.orderData[order.orderData.length - 2].Discount = {
              label: `${key.replace(/[Discount]/g, '')}`,
              value: order.orderData[order.orderData.length - 2][key],
            }
          }

          if (!key.includes('Discount') && !key.includes('Pajak')) {
            order.orderData[order.orderData.length - 2][key] = {
              label: key,
              value: order.orderData[order.orderData.length - 2][key],
            }
          }
        }
        order.orderDetails = {
          ...order.orderData[order.orderData.length - 2],
        }
        order.orderType = order.orderData[order.orderData.length - 2]['Order Type'].value
        order.orderData.forEach(d => {
          if (d.hasOwnProperty('Sub Total') === false && d.hasOwnProperty('ShopID') === false) {
            order.itemsOrdered.push(d)
          }
        })

        order.breadCrumbs = [
          {
            text: this.getStatusName(order.status),
            disabled: false,
          },
          {
            text: order._id,
            disabled: false,
          },
          {
            text: this.$moment(order.timestamp).format('DD MMM YYYY HH:MM:SS'),
            disabled: false,
          },
        ]

        order.itemsOrdered.forEach(el => {
          if (el.AddOn !== '-') {
            el.listAddon = this.addOnProcessor(el.AddOn)
          }
        })
      })

      if (this.currentTab === 1) {
        orderList = orderList.filter(order => order.status === 0)
        if (this.selectedSort !== '') {
          orderList = orderList.filter(order => order.orderType === this.selectedSort)
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        } else {
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        }

        return _orderBy(orderList, 'timestamp', this.selectedFilter)
      }
      if (this.currentTab === 2) {
        orderList = orderList.filter(order => order.status === 1)
        if (this.selectedSort !== '') {
          orderList = orderList.filter(order => order.orderType === this.selectedSort)
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        } else {
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        }

        return _orderBy(orderList, 'timestamp', this.selectedFilter)
      }
      if (this.currentTab === 3) {
        orderList = orderList.filter(order => order.status === 2)
        if (this.selectedSort !== '') {
          orderList = orderList.filter(order => order.orderType === this.selectedSort)
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        } else {
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        }

        return _orderBy(orderList, 'timestamp', this.selectedFilter)
      }

      if (this.currentTab === 4) {
        orderList = orderList.filter(order => order.status === 3)
        if (this.selectedSort !== '') {
          orderList = orderList.filter(order => order.orderType === this.selectedSort)
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        } else {
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        }

        return _orderBy(orderList, 'timestamp', this.selectedFilter)
      }

      if (this.currentTab === 5) {
        orderList = orderList.filter(order => order.status === 4)
        if (this.selectedSort !== '') {
          orderList = orderList.filter(order => order.orderType === this.selectedSort)
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        } else {
          orderList.forEach(order => {
            this.selectOrder.push({
              id: order._id,
              value: false,
            })
            this.showDetail.push({
              id: order._id,
              value: false,
            })
          })
        }

        return _orderBy(orderList, 'timestamp', this.selectedFilter)
      }

      if (this.selectedSort !== '') {
        orderList = orderList.filter(order => order.orderType === this.selectedSort)
        orderList.forEach(order => {
          this.selectOrder.push({
            id: order._id,
            value: false,
          })
          this.showDetail.push({
            id: order._id,
            value: false,
          })
        })
      } else {
        orderList.forEach(order => {
          this.selectOrder.push({
            id: order._id,
            value: false,
          })
          this.showDetail.push({
            id: order._id,
            value: false,
          })
        })
      }

      //console.log(orderList, `INI ORDERLIST`)

      return _orderBy(orderList, 'timestamp', this.selectedFilter)
    },
  },
  async mounted() {
    if (this.$route.params.tab === 3) {
      this.currentTab = 4
    } else if (this.$route.params.tab === 4) {
      this.currentTab = 5
    } else {
      this.currentTab = this.$route.params.tab
    }

    // //console.log(this.$route.params, `INI ROUTER`)
    await this.connectEordering()
    await this.getShop({ sub_id: this.user.sub_id })
    await this.loadOrderList()
    await this.runEventErodering()
    this.orderListInfo = [
      {
        text: 'E-Shop',
        disabled: false,
        link: true,
        exact: true,
        to: {
          name: 'e-ordering',
        },
      },
      {
        text: 'Order List',
        disabled: true,
      },
      {
        text: this.currentShop.name,
        disabled: true,
      },
    ]

    this.orderList.forEach(order => {
      this.selectOrder.push({
        id: order._id,
        value: false,
      })
      this.showDetail.push({
        id: order._id,
        value: false,
      })
    })

    const REALTIME_URL = 'wss://realtime.superwa.io/socket'
    const client = new RealtimeClient(REALTIME_URL)
    client.connect()
    client.onOpen(() => console.log('Socket eordering opened.'))
    client.onClose(() => console.log('Socket eordering closed.'))
    client.onError(e => console.log('Socket eordering error', e))

    const databaseChanges = client.channel(`realtime:${this.user.sub_id}:eorderings`)
    databaseChanges.on('INSERT', e => this.updateEordering(e))
    databaseChanges.on('UPDATE', e => this.updateEordering(e))
    databaseChanges.subscribe()

    // //console.log(this.orderList, `INI ORDER`)
  },
  methods: {
    addOnProcessor(text) {
      const addOn = text.split(', ')

      return addOn.map(el => {
        // if (el.split(' - ')[1]) {
        return {
          text: el.split(' - ')[0],
          price: Number(el.split(' - ')[1].replace('Rp.', '')),
        }

        // }

        // return {
        //   text: el.split(' - ')[0],
        //   price: 6969,
        // }
      })
    },
    async updateEordering(e) {
      // //console.log(e, `INI UPDATE`)
      if (this.currentShop !== null) {
        const allOrderData2 = await this.loadEorderingOrder({
          sub_id: this.user.sub_id,
          shopId: this.currentShop.id,
          startDate: this.startDate,
          endDate: this.endDate,
        })

        this.orderList = allOrderData2

        const allOrders = await this.loadEorderingAllOrder({ sub_id: this.user.sub_id, shopId: this.currentShop.id })

        this.totalShopRevenue = 0
        allOrders.forEach(order => {
          order.orderData.forEach(orderDetail => {
            if (orderDetail.Total && order.status === 3) {
              this.totalShopRevenue += orderDetail.Total
            }
          })
        })
      }
    },
    showMoreProduct(orderId, indexOrder) {
      this.showDetail[indexOrder].value = !this.showDetail[indexOrder].value

      //  if (this.showDetail[indexOrder].value === true) {
      //         this.selectBulk = true
      //         this.selectedOrders.push(orderId)
      //       } else if (this.showDetail[indexOrder].value === false) {
      //         this.selectedOrders = this.selectedOrders.filter(order => order !== orderId)
      //       }
      //       if (this.selectedOrders.length === 0) {
      //         this.selectBulk = false
      //       }
    },
    async filterProduct() {
      let filtered = []
      await this.getErodering({
        sub_id: this.user.sub_id,
        shopId: this.currentShop.id,
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.orderList.forEach(order => {
        order.orderData.forEach(data => {
          if (data.name && data.name.toLowerCase().includes(this.currentSearch)) {
            filtered.push(order)
          }
        })
      })

      if (filtered.length !== 0 && this.currentSearch !== '') {
        this.orderList = JSON.parse(JSON.stringify(filtered))
      } else if (filtered.length === 0 && this.currentSearch !== '') {
        this.orderList = []
        filtered = []
      } else if (filtered.length === 0 && this.currentSearch === '') {
        filtered = []
      }
    },
    async loadOrderDataOnChange() {
      let startDate
      let endDate

      if (this.dates.length === 1 || new Date(this.dates[0]).getTime() === new Date(this.dates[1]).getTime()) {
        startDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate()))
          .toISOString()
          .substring(0, 10)

        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate()))
          .toISOString()
          .substring(0, 10)
        startDate = new Date(startDate).setHours(0, 0, 0, 0)
        endDate = new Date(endDate).setHours(24, 0, 0, 0)

        // startDate = new Date(
        //   new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() - 1),
        // )
        //   .toISOString()
        //   .substring(0, 10)

        // endDate = new Date(
        //   new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1),
        // )
        //   .toISOString()
        //   .substring(0, 10)
      } else if (new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime() && this.dates.length > 1) {
        startDate = new Date(this.dates[1]).getTime()
        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      } else {
        startDate = new Date(this.dates[0]).getTime()
        endDate = new Date(new Date(this.dates[1]).setDate(new Date(this.dates[1]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      }
      endDate = new Date(endDate).getTime()
      this.endDate = endDate
      this.startDate = startDate

      await this.getErodering({ sub_id: this.user.sub_id, shopId: this.currentShop.id, startDate, endDate })
    },

    // debounceOrder() {
    //   _debounce(async function () {
    //     await this.getErodering({ sub_id: this.user.sub_id, shopId: this.currentShop.id, startDate, endDate })
    //   }, 1000)
    // },
    resetSelection() {
      this.selectBulk = false
      this.selectOrders = []
      this.selectedOrders = []
      this.orderList.forEach((order, i) => {
        this.selectOrder[i].value = false
        this.showDetail[i].value = false
      })
    },
    async setOrder(orderId, indexOrder) {
      if (this.selectOrder[indexOrder].value === true) {
        this.selectBulk = true
        this.selectedOrders.push(orderId)
      } else if (this.selectOrder[indexOrder].value === false) {
        this.selectedOrders = this.selectedOrders.filter(order => order !== orderId)
      }
      if (this.selectedOrders.length === 0) {
        this.selectBulk = false
      }

      // this.orderList.forEach((order, i) => {
      //   if (this.selectOrder[i].value === false) {
      //     this.selectBulk = false
      //     this.selectOrders = []
      //     this.selectedOrders = []
      //   }
      // })

      // //console.log(orderId, `INI ORDER ID`)
    },
    async chooseAllOrder() {
      if (this.selectBulk === true) {
        this.selectedOrders = this.orders.map(order => order._id)
        this.orderList.forEach((order, i) => {
          this.selectOrder[i].value = true
        })
      } else if (this.selectBulk === false) {
        this.selectOrders = []
        this.selectedOrders = []
        this.orderList.forEach((order, i) => {
          this.selectOrder[i].value = false
        })
      }

      // //console.log(this.selectBulk, this.selectedOrders)
    },
    async bulkUpdateOrderStatus(status) {
      const updatePayload = {
        sub_id: this.user.sub_id,
        orderStatus: status,
        orderId: this.selectedOrders,
      }

      const res = await this.$store.dispatch('eOrdering/bulkUpdateStatus', updatePayload)
      this.selectBulk = false
      this.selectOrders = []
      this.selectedOrders = []
      this.orderList.forEach((order, i) => {
        this.selectOrder[i].value = false
      })

      //console.log(status, this.selectedOrders, res.data, `ini bulk udpate`)
    },
    async changeOrderStatus(orderId, status) {
      const statusPayloadEdit = {
        orderId,
        sub_id: this.user.sub_id,
        orderStatus: status,
      }
      const res = await this.$store.dispatch('eOrdering/editOrderShop', statusPayloadEdit)

      // //console.log(true, status, orderId, res, `INI KE KLIK`)
    },
    getColorOrderType(type) {
      if (type === 'Dine In') return '#ae9ea4'
      if (type === 'Take Away') return '#9E643C'
      if (type === 'Delivery') return '#99C24D'
      if (type === 'Reservation') return '#F18F01'

      return '#006E90'
    },
    currency(data, type = 'IDR', lang = 'id') {
      return data.toLocaleString(lang, { style: 'currency', currency: type })
    },
    getColor(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'primary'
      if (status === 3) return 'success'

      return 'error'
    },
    getStatusName(status) {
      if (status === 0) return 'Pending'
      if (status === 1) return 'On Progress'
      if (status === 2) return 'Paid'
      if (status === 3) return 'Done'

      return 'Canceled'
    },
    async loadOrderList() {
      let startDate
      let endDate

      if (this.dates.length === 1) {
        startDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() - 1))
          .toISOString()
          .substring(0, 10)

        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      } else if (new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime() && this.dates.length > 1) {
        startDate = new Date(this.dates[1]).getTime()
        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      } else {
        startDate = new Date(this.dates[0]).getTime()
        endDate = new Date(new Date(this.dates[1]).setDate(new Date(this.dates[1]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      }
      endDate = new Date(endDate).getTime()
      this.endDate = endDate
      this.startDate = startDate

      await this.getErodering({ sub_id: this.user.sub_id, shopId: this.currentShop.id, startDate, endDate })
    },
  },
}
</script>

<style scoped>
/* .v-breadcrumbs {
  color: black;
} */

.shop-logo {
  width: 77px;
  height: 77px;
  border-radius: 10px;
}

.border-divider {
  border-left: 2px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  margin-top: 12px;
  margin-bottom: 12px;
}

.border-divider-top {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  margin-top: 12px;
  margin-bottom: 12px;
}

.toggleUpDown {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
  transform: rotate(240deg);
}
</style>
